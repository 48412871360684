@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: auto !important;
}

* {
  outline: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Inter', sans-serif;
  @apply text-slate-800;
}

.main-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 0;
  /* background: url('./img/main-bg.jpg') center top / 3500px no-repeat; */
  z-index: 0;
  opacity: 0.6;
  background-color: #ebebeb;
}

.input-wrap {
  @apply flex flex-col w-full relative mt-4;
}

.input-wrap input {
  @apply z-20 relative;
}

.input-wrap label {
  @apply text-gray-600  text-xs absolute top-3 z-10;
  width: 150px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.input-wrap input:not([type='submit']) {
  @apply border-b border-wba-secondary text-black text-sm;
  background-color: transparent !important;
  height: 40px;
}

.input-wrap input[type='submit'] {
  @apply text-white bg-wba-primary hover:bg-wba-primary-hover text-center py-2 w-full rounded-md cursor-pointer mt-2;
}

.input-wrap input[type='submit']:disabled {
  @apply pointer-events-none opacity-70;
}

.input-check {
  @apply absolute right-2 top-3;
}

.input-check.valid {
  @apply text-green-500;
}

.input-check.invalid {
  @apply text-red-500;
}

.toggle input:checked {
  /* background-color: #22c55e; bg-green-500 */
  @apply bg-blue-500
}

.toggle input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}

.instructions {
  @apply absolute top-full left-0 p-2 text-sm rounded-md bg-blue-200 mt-2 z-50;
}

.instructions.failed {
  @apply bg-red-200;
}

.instructions::after {
  content: '';
  position: absolute;
  left: 10px;
  top: -6px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-bottom: 7px solid rgb(191 219 254);
}

.instructions.failed::after {
  border-bottom: 7px solid rgb(254, 202, 202);
}

.instructions svg {
  @apply mr-1 text-blue-700;
}

.instructions.failed svg {
  @apply mr-1 text-red-700;
}

.input-check.hide {
  @apply hidden;
}

.input-wrap input::placeholder {
  font-size: 0;
}

.input-wrap input:focus-within ~ label,
.input-wrap input:not(:placeholder-shown) ~ label {
  @apply transform scale-80 -translate-y-5 -translate-x-4 text-wba-primary;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* styles.css */
.custom-datepicker-button {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  text-align: left;
  width: 100%;
}

.wba-error {
  @apply text-center w-full text-white bg-red-500 rounded-md py-2 px-2 text-sm;
}

.nav-link {
  @apply flex items-center py-2.5 px-3 font-normal text-wba-gray rounded-md hover:bg-white cursor-pointer;
}

.nav-link.active {
  @apply bg-gray-300;
}

.nav-link.primary {
  @apply text-white bg-wba-primary hover:bg-wba-primary-hover;
}

.nav-link svg {
  @apply flex-shrink-0 w-6 h-6 text-wba-gray group-hover:text-wba-gray;
}

.nav-link.primary svg {
  @apply text-white group-hover:text-white;
}

/* .nav-link.nuxt-link-exact-active svg {
  @apply text-white group-hover:text-white
} */

.nav-link .info {
  @apply inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full;
}

.nav-link .info-blue {
  @apply inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full;
}

.content-box {
  @apply bg-white rounded-md border border-gray-300 pt-4 mb-4;
}

.content-box h1 {
  @apply font-bold text-xl;
}

.content-box h2 {
  @apply font-medium text-gray-700 text-sm;
}

.content-box .content-icon {
  font-size: 60px;
  @apply my-4;
}


.btn {
  @apply text-sm inline-flex justify-center items-center cursor-pointer bg-blue-500 border-blue-500 hover:bg-blue-700 text-white font-bold py-2.5 px-4 border rounded;
}

.btn.disabled {
  @apply opacity-50 bg-red-700 cursor-not-allowed;
}

.btn.clear {
  @apply text-blue-500 bg-transparent;
}

.btn.green {
  @apply bg-emerald-500 border-emerald-500 hover:bg-emerald-600;
}

.btn.active {
  @apply bg-emerald-600 border-emerald-600 hover:bg-emerald-500;
}

.btn.red {
  @apply bg-red-600 border-red-600 hover:bg-red-700;
}

.btn.gray {
  @apply bg-gray-600 border-gray-600 hover:bg-gray-700;
}

.btn.warning {
  @apply bg-blue-500 border-blue-500 hover:bg-blue-600;
}

.btn.small {
  @apply py-2 px-2 font-normal;
}


.btn.primary {
  @apply bg-wba-primary hover:bg-wba-primary-hover border-wba-primary-hover;
}

.btn.grey {
  @apply bg-slate-400 border-slate-400 hover:bg-slate-500 text-xs;
}

.inner-content-box {
  @apply mt-8 pb-8 overflow-auto;
  max-height: 280px;
}

.loader {
  -webkit-animation: spinner 0.75s linear infinite;
  animation: spinner 0.75s linear infinite;
  @apply border-t-wba-primary;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profile-pic {
  @apply w-full h-full block relative pointer-events-none;
  background-size: cover;
}

.primary-appointment {
  @apply border border-wba-primary;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.dropdown-parent {
  @apply relative z-50;
}

.dropdown-box {
  @apply origin-top-right absolute right-0 mt-3 w-52 rounded-md shadow-md bg-white px-2 py-2 border border-gray-200;
}

.dropdown-box li {
  @apply py-2 px-2 hover:bg-gray-100 rounded-md cursor-pointer;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.errmsg {
  @apply bg-red-500 text-sm text-center py-2 px-2 rounded-md text-white mb-6 flex justify-center items-center relative;
}

.rdp-day_today:not(.rdp-day_selected) {
  background-color: #e2e2e2 !important;
}

.profile-pic {
  background: center center / cover no-repeat;
  @apply w-full h-full block relative border border-gray-100 cursor-pointer;
}

.settings-link a {
  @apply bg-white rounded-xl shadow-md min-h-[150px] flex justify-center items-center flex-col p-4 hover:bg-blue-200;
}

.settings-link {
  @apply w-1/3 px-3 mb-6;
}

.settings-link h2 {
  @apply font-bold text-xl text-slate-800;
}

.settings-link p {
  @apply mb-6 text-sm text-slate-800;
}

.settings-link span {
  @apply w-3/4 pointer-events-none;
}

.info-box > form,
.info-box > div {
  @apply bg-white rounded-xl shadow-md min-h-[150px] flex justify-center items-center flex-col p-4;
}

.info-box {
  @apply w-1/3 px-3 mb-6;
}

.info-box input,
.info-box select {
  @apply px-4 py-2 block border border-gray-300 rounded-md shadow-md w-3/4 mb-4;
}

.info-box table input {
  @apply w-full;
}

.info-box p {
  @apply mb-6 text-sm text-white;
}

.info-box > form > .errmsg {
  @apply mb-4 mt-2 w-3/4;
}

.info-box > div > span,
.info-box > form > span {
  @apply w-3/4;
}

.info-box h2 {
  @apply font-bold text-xl mb-4;
}

.floating-info.error {
  @apply bg-red-500 text-white border-red-600;
}

.floating-info.error .icon {
  @apply text-white bg-red-700;
}

.floating-info.info {
  @apply bg-blue-500 text-white border-blue-600;
}

.floating-info.info .icon {
  @apply text-white bg-blue-700;
}

.floating-info.success {
  @apply bg-emerald-500 text-white border-emerald-600;
}

.floating-info.success .icon {
  @apply text-white bg-green-700;
}

.floating-info {
  visibility: visible;
  max-width: 65px;
  height: 65px;
  margin: auto;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 1;
  /* left: 0; */
  right: 20px;
  bottom: 30px;
  font-size: 17px;
  z-index: 9999;
  /* white-space: nowrap; */
  @apply flex items-center rounded-md shadow-md overflow-hidden;
}

.floating-info .icon {
  width: 65px;
  height: 65px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  color: #fff;
  @apply flex justify-center items-center text-2xl;
}

.floating-info .text {
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  @apply flex justify-center items-center font-bold text-sm;
}

.floating-info.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 4s 1s, shrink 0.5s 3s,
    fadeout 0.5s 3.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s, stay 4s 1s, shrink 0.5s 5s,
    fadeout 0.5s 5.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes expand {
  from {
    min-width: 65px;
  }
  to {
    min-width: 400px;
  }
}

@keyframes expand {
  from {
    min-width: 65px;
  }
  to {
    min-width: 400px;
  }
}
@-webkit-keyframes stay {
  from {
    min-width: 400px;
  }
  to {
    min-width: 400px;
  }
}

@keyframes stay {
  from {
    min-width: 400px;
  }
  to {
    min-width: 400px;
  }
}
@-webkit-keyframes shrink {
  from {
    min-width: 400px;
  }
  to {
    min-width: 65px;
  }
}

@keyframes shrink {
  from {
    min-width: 400px;
  }
  to {
    min-width: 65px;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 60px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 60px; opacity: 0;}
}

.tab-btn {
  @apply flex justify-center items-center font-medium hover:shadow text-xs uppercase mr-2 2xl:mr-4 px-4 py-2 2xl:px-6 2xl:py-3 hover:border-transparent hover:bg-gray-300 hover:text-gray-800 rounded-md cursor-pointer transition-all duration-200;
}

.tab-btn.active{
    @apply bg-blue-500 text-white shadow;
}

.tab-btn-underline {
  @apply block font-medium text-sm px-6 text-center py-3 cursor-pointer rounded-md bg-gray-100 hover:bg-gray-200;
 
}
.tab-btn-underline.active{
    @apply bg-blue-500 text-white pointer-events-none;
}

.pill {
  @apply my-2 inline-flex bg-teal-500 px-6 py-3 font-medium text-white cursor-default;
  border-radius: 4px 0px 0px 4px;
}

.pill-qr {
  @apply my-2 inline-flex px-4 py-1.5 font-medium text-white text-sm;
  border-radius: 4px 0px 0px 4px;
}

.pill-button
{
@apply my-2 inline-flex rounded-md bg-red-500 px-4 text-lg font-medium uppercase text-white mr-4 items-center hover:bg-red-600 cursor-pointer;
border-radius: 0px 4px 4px 0px;
}

.pill-button-qr
{
@apply my-2 inline-flex rounded-md bg-red-500 px-2.5 text-sm font-medium uppercase text-white mr-4 items-center hover:bg-red-600 cursor-pointer;
border-radius: 0px 4px 4px 0px;
}

.pill.small {
  @apply my-0 px-3 py-2 font-normal text-xs ml-2;
}

.pill.slate {
  @apply bg-slate-500/80
}

.small.pill-button
{
@apply my-0 inline-flex rounded-md bg-red-500 px-2 text-xs font-medium uppercase text-white mr-0 items-center hover:bg-red-600 cursor-pointer;
border-radius: 0px 4px 4px 0px;
}

.status {
  @apply py-1 px-2 rounded-full text-white font-semibold text-sm inline-flex justify-center items-center;
  min-width: 100px;
}

.status.active{
  @apply bg-emerald-500 text-white;
}

.status.pending{
  @apply bg-yellow-400 text-slate-800;
}

.status.disabled{
  @apply bg-red-500;
}

.status.private
{
  @apply bg-purple-500
}

.status.public
{
  @apply bg-green-500
}


.status.awaiting-assessment{
  @apply bg-orange-400 text-white px-2;
}

.status.no-cover{
  background-color: #FF991F;
  color: #fff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.status.awaiting-claim{
  background-color: #FF991F;
  color: #fff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.status.send-triage-options{
  background-color: transparent;
  border: 1px solid #FF991F;
  color: #FF991F;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.status.awaiting-response{
  background-color: transparent;
  border: 1px solid #4C9AFF;
  color: #4C9AFF;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.status.missed-reservation-window{
  background-color: transparent;
  border: 1px solid #FF5630;
  color: #FF5630;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.status.discharged-pre-assessment {
  @apply bg-red-700 text-white px-2;
}

.status.new-options-requested{
  background-color: transparent;
  border: 1px solid #FF991F;
  color: #FF991F;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.status.upcoming{
  @apply bg-transparent text-blue-600 px-2;
}

.status.pending{
  @apply bg-transparent text-orange-500 px-2;
}

.status.cancelled{
  @apply bg-transparent text-red-600 px-2;
}

.status.discharged{
  @apply bg-red-700 text-white px-2;
}

.status.overdue{
  @apply bg-transparent text-red-600 px-2;
}

.status.completed{
  @apply bg-transparent text-emerald-600 px-2;
}

.currency-wrap{
  position:relative;
}

.currency-code{
  position:absolute;
  left:8px;
  top:10px;
}

.text-currency{
  padding:10px 20px;
  border:solid 1px #ccc;
  border-radius:5px;
}

.article-item {
  @apply rounded-md w-full px-4 py-4 mb-6 border relative;
}

.article-item.notification-list {
  @apply p-3 mb-3
}

.article-item.system
{
  @apply border-emerald-400 bg-green-100/20
}

.article-item.admin
{
  @apply border-blue-400 bg-blue-100/20
}

.notification-parent:nth-of-type(odd) {
  @apply pr-3
}

.notification-parent:nth-of-type(even) {
  @apply pl-3
}

.ping {
  @apply relative;
}

.ping::after {
  content: '';
  position: absolute;
  top: 0;
  right: 6px;
  width: 6px;
  height: 6px;
  @apply rounded-full bg-pink-500;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%, 100% {
    transform: scale(1.75);
    opacity: 0;
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-main {
  @apply min-w-full table-auto rounded-md overflow-hidden;
}

.table-main thead tr {
  @apply bg-blue-500 text-white leading-normal;
}

.table-main thead th {
  @apply py-2 px-6 text-center;
}

.table-main tbody {
  @apply text-slate-800 text-sm;
}

.table-main tbody tr {
  @apply border-b odd:bg-white even:bg-blue-100/50 border-gray-200;
}

.table-main tbody td {
  @apply py-2 px-6 text-center whitespace-normal break-words;
}

.table-main tbody tr.table-sub {
  @apply bg-slate-400 text-white;
}

.table-main tbody tr.table-sub td {
  @apply text-base;
}

.table-main .no-records {
  @apply py-3 text-center text-sm font-semibold w-full;
}

/* calendar */

.toastui-calendar-panel.toastui-calendar-week-view-day-names {
  overflow-y: hidden!important;
}

.toastui-calendar-panel.toastui-calendar-time {
  height: 100%!important;
}

.select-error {
  @apply relative;
}

.select-error select {
  @apply border-red-600 relative
}

.select-error .error-icon {
  @apply absolute top-[30px] right-8 text-red-500
}

.calendar-block > div {
  height: calc(100vh - 350px)!important;
  max-width: 100%;
}

.badge {
  display: inline-block;
  padding: 4px 2px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }


.badge-pill {
  border-radius: 50px }

select {
  height: 45px;
}


.message-bubble.--New {
  @apply bg-slate-300
}

.message-bubble.--Sending,
.message-bubble.--Holding {
  @apply bg-orange-200
}

.message-bubble.--Sent {
  @apply bg-emerald-200
}

.message-bubble.--Failed {
  @apply bg-red-300
}

.drop-file-preview__item { 
  position: relative; 
  border-radius: 20px; 
}  
 
.drop-file-preview__item__del { 
  position: absolute; 
  right:10px; 
  top: 50%; 
  transform: translateY(-50%); 
  cursor: pointer;  
  opacity:0; 
  transition: opacity 0.3s ease;
}
.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity:1;
} 
.pills{
  padding: 0.425rem;
    border: 1px solid #ced4da;
    border-radius: 0.35rem 0 0 0.35rem;
    flex-grow: 1;

    
}

.pills .pill-box{
  display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: hidden;
    padding-bottom: 8px;
}

.pills .controlls{
  display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #e9ecef;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0 0.35rem 0.35rem 0;
    border-left: none;
}

.drop-file-preview__item { 
  position: relative; 
  border-radius: 20px; 
}  
 
.drop-file-preview__item__del { 
  position: absolute; 
  right:10px; 
  top: 50%; 
  transform: translateY(-50%); 
  cursor: pointer;  
  opacity:0; 
  transition: opacity 0.3s ease;
}
.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity:1;
} 
  
  
  
  
